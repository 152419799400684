/* global isJestTest */
/**
 * CardEditorialList
 */

import React from 'react';
import CardEditorial from 'Components/CardEditorial';
import { httpGet } from 'utils/Http';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { objToQuery } from 'utils/Helpers';
import { snakeCaseToCamelCase } from 'utils';
import FilterSelect from 'Components/FilterSelect';
import ButtonReadMore from 'Components/ButtonReadMore';
import AjaxLoader from 'Components/AjaxLoader';
import { dataLayerPush } from 'utils/Datalayer';
import Title from 'Components/Title';
import s from './CardEditorialList.module.scss';

class CardEditorialList extends React.PureComponent {
    pushState = false;
    constructor(props) {
        super(props);

        this.state = {
            showShowAllButton: false,
            selectedPlace: this.props.preselectedPlaces,
            selectedCategory: this.props.preselectedCategories,
            selectedBusinessConcepts: this.props.preselectedBusinessConcepts,
            selectedBusinessPartners: this.props.preselectedBusinessPartners,
            page: 1,
            count: 0,
            entries: [],
        };
    }

    handlePlaceOnChange = (event) => {
        this.pushState = true;
        this.setState({
            selectedPlace: [event.value],
            page: 1,
            entries: []
        }, () => this.fetchCards(false));
    };

    handleCategoryOnChange = (event) => {
        this.pushState = true;
        this.setState({
            selectedCategory: [event.value],
            page: 1,
            entries: []
        }, () =>
            this.fetchCards(false)
        );
    };

    handleListExtension = () => {
        const self = this;

        const { page } = this.state;

        this.pushState = false;

        this.setState({
            page: page + 1,
            fetching: true,
        }, () => {
            self.fetchCards(false);
        });
    }

    componentDidMount() {
        const self = this;
        if (!window?.isJestTest && typeof window !== 'undefined') {
            const params = new URLSearchParams(window.location.search);

            let state = {};
            const place = params.get('place');
            if(place) {
                state.selectedPlace = place.split(',').map((p) => parseInt(p));
            }
            const category = params.get('category');
            if(category) {
                state.selectedCategory = category.split(',').map((c) => parseInt(c));
            }

            this.setState({...state}, () => {
                self.fetchCards(true);
            });

            this.pushState = false;

            window.onpopstate = function (event) {
                self.pushState = false;
                self.setState({
                    ...event.state,
                    fetching: true,
                }, () => {
                    self.fetchCards(false);
                });
            };
        }
    }

    getBaseUrl = () => {
        const url = window.location.host + window.location.pathname;
        return window.location.protocol + '//' + url;
    }

    fetchCards = (isInitialSearch) => {
        const {
            selectedPlace,
            selectedCategory,
            selectedBusinessPartners,
            selectedBusinessConcepts,
            page,
            entries,
        } = this.state;

        const {
            places,
            categories,
            currentPage,
            showRelated,
            onShowComponent,
            onHideComponent,
            initialNumberOfCards,
            pageListId,
            showFeaturedFirst,
            priorityQuery,
            excludeKeywords,
        } = this.props;

        let url = '';
        if (showRelated) {
            url = `/wt/api/v2/related-editorial/?id=${currentPage}&page=1&take=3`;
        }
        else {
            const parsedPlace = selectedPlace ? selectedPlace.join(',') : '';
            const parsedCat = selectedCategory ? selectedCategory.join(',') : '';
            const queries = {
                exclude: currentPage || '',
                place: parsedPlace,
                category: parsedCat,
                priorityQuery: priorityQuery ? priorityQuery.join(',') : '',
                excludeKeywords: excludeKeywords ? excludeKeywords.join(';') : '',
                businessPartners: selectedBusinessPartners ? selectedBusinessPartners.join(',') : '',
                businessConcepts: selectedBusinessConcepts ? selectedBusinessConcepts.join(',') : '',
                fallbackToParentPlace: isInitialSearch ? false : true,
                take: initialNumberOfCards || 3,
                page: page || 1,
                showFeaturedFirst: showFeaturedFirst || false,
            };

            url = `/wt/api/v2/editorial/${objToQuery(queries)}`;

            if (this.pushState) {
                const defaultPlace = places.length ? places[0] : '';
                const defaultCat = categories.length ? categories[0] : '';
                let pushQuery = {};
                if(parsedPlace && defaultPlace && parsedPlace !== String(defaultPlace.id)) {
                    pushQuery.place = parsedPlace;
                }
                if(parsedCat && defaultCat && parsedCat !== String(defaultCat.id)) {
                    pushQuery.category = parsedCat;
                }
                const pushUrl = `${this.getBaseUrl()}${objToQuery(pushQuery)}`;
                history.pushState(this.state, '', pushUrl);
            }
        }

        this.setState({
            fetching: true,
        });

        httpGet(url).then((response) => {
            const formattedResponse = snakeCaseToCamelCase(response);
            this.setState({
                fetching: false,
                count: formattedResponse.count,
                entries: entries.concat(formattedResponse.results),
                showShowAllButton: page === 4 && pageListId !== currentPage,
            });

            if (isInitialSearch && formattedResponse.results.length) {
                onShowComponent();
            }
            else if (isInitialSearch && !formattedResponse.results.length) {
                onHideComponent();
            }
            if (!isInitialSearch) {
                dataLayerPush({
                    'event': 'search',
                    'searchType': 'editorial',
                    'searchInput': '',
                    'hits': formattedResponse ? formattedResponse.length : 0,
                    'citySearch': selectedPlace ? selectedPlace.join(',') : '',
                    'searchCategory': selectedCategory ? selectedCategory.join(',') : '',
                });
            }
        });
    };

    render() {
        const {
            identifier,
            title,
            tag,
            showFilter,
            pageListUrl,
            extendable,
            vertical,
            showRelated,
            hideCategoriesField,
            showFeaturedFirst,
        } = this.props;

        let { places, categories } = this.props;

        const {
            selectedPlace,
            selectedCategory,
            entries,
            fetching,
            showShowAllButton,
            count,
        } = this.state;

        const classes = classNames(
            s['CardEditorialList'],
            {[s['CardEditorialList--Vertical']]: vertical},
            {[s['CardEditorialList--Filter']]: showFilter},
            {[s['CardEditorialList--Related']]: showRelated},
        );

        return (
            <div className={classes}>
                <Title title={title} tag={tag}>
                    {showFilter && (
                        <div className={s['CardEditorialList__FilterWrapper']}>
                            {places && places.length > 0 && (
                                <div className={s['CardEditorialList__SelectWrapper']}>
                                    <FilterSelect
                                        id={`${identifier}-card-editorial-list-place`}
                                        value={
                                            selectedPlace &&
                                            selectedPlace.length
                                                ? selectedPlace[0]
                                                : ''
                                        }
                                        placeholder="Välj plats"
                                        onChange={this.handlePlaceOnChange}
                                        options={places.map((place) => ({
                                            value: place.id,
                                            label: place.name,
                                            isCounty: place.isCounty,
                                        }))}
                                        icon="Location"
                                        isLocation={true}
                                    />
                                </div>
                            )}

                            {categories && categories.length > 0 && !hideCategoriesField && (
                                <div className={s['CardEditorialList__SelectWrapper']}>
                                    <FilterSelect
                                        id={`${identifier}-card-editorial-list-category`}
                                        value={
                                            selectedCategory &&
                                            selectedCategory.length
                                                ? selectedCategory[0]
                                                : ''
                                        }
                                        placeholder="Välj ämne"
                                        onChange={this.handleCategoryOnChange}
                                        options={categories.map((category) => ({
                                            value: category.id,
                                            label: category.name,
                                        }))}
                                        icon="Category"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </Title>
                {entries && (
                    <div className={s['CardEditorialList__CardsWrapper']}>
                        {entries.map((card, index) => (
                            <div
                                className={s['CardEditorialList__Card']}
                                key={index}>
                                <CardEditorial
                                    vertical={vertical}
                                    highlightFeatured={showFeaturedFirst}
                                    {...card}
                                />
                            </div>
                        ))}
                    </div>
                )}

                {fetching && (
                    <div className={s['CardEditorialList__Loader']}>
                        <AjaxLoader />
                    </div>
                )}

                {!fetching && (!entries || !entries.length) && (
                    <h3 className={s['CardEditorialList__NoHits']}>
                        Vi hittade inga berättelser som matchar din sökning
                    </h3>
                )}

                {!fetching && !showShowAllButton && extendable && entries.length < count && (
                    <ButtonReadMore
                        text="Visa fler"
                        onClick={this.handleListExtension}
                    />
                )}

                {((!extendable && pageListUrl) || (showShowAllButton && pageListUrl)) && (
                    <a
                        href={pageListUrl}
                        className={s['CardEditorialList__GoToPage']}>
                    Visa alla
                    </a>
                )}
            </div>
        );
    }
}

CardEditorialList.defaultProps = {
    identifier: '',
    cardeditorial: [],
    showFilter: true,
    places: [],
    categories: [],
    initialNumberOfCards: 3,
    extendable: true,
    vertical: false,
    excludeKeywords: [],
    onHideComponent: () => {},
    onShowComponent: () => {},
};

CardEditorialList.propTypes = {
    initialNumberOfCards: PropTypes.number,
    extendable: PropTypes.bool,
    vertical: PropTypes.bool,
    excludeKeywords: PropTypes.array,
};

export default CardEditorialList;
