/**
 * CardEditorial
 */

import React from 'react';
import classNames from 'classnames';
import Image from 'Components/Image';
import TagList from 'Components/TagList';
import Logo from 'Components/Logo';
import s from './CardEditorial.module.scss';

const CardEditorial = ({
    pageUrl = '',
    image = {},
    title = '',
    regions = [],
    text = '',
    genres = [],
    vertical = false,
    formattedShortPublishedAt = '',
    isFeatured = false,
    highlightFeatured = false,
}) => {
    const showFeaturedLabel = isFeatured && highlightFeatured;
    const classes = classNames(
        s['CardEditorial'],
        {[s['CardEditorial--Vertical']]: vertical },
        {[s['CardEditorial--NoImage']]: !image || !image.src },
        {[s['CardEditorial--Featured']]: showFeaturedLabel },
    );

    const regionlist = regions.map((region) => region.name).join(', ');
    const imageSizes = [
        '(min-width: 1200px) 365px',
        '(min-width: 960px) 33vw',
        '100vw',
    ];

    return (
        <a href={pageUrl} className={classes}>
            {image && image.src ? (
                <div className={s['CardEditorial__Image']}>
                    <Image {...image} sizes={imageSizes} />
                </div>
            ) : (
                <div className={classNames(s['CardEditorial__Image'], s['CardEditorial__Image--Placeholder'])}>
                    <Logo
                        className={[s['CardEditorial__Logo']]}
                        asBackground={true}
                    />
                </div>
            )}
            <div className={s['CardEditorial__Content']}>
                <h2 className={s['CardEditorial__Title']}>{title}</h2>
                {regionlist && (
                    <span className={s['CardEditorial__Region']}>{regionlist}</span>
                )}
                {text && <p className={s['CardEditorial__Text']}>{text}</p>}
                <span className={s['CardEditorial__Date']}>
                    {formattedShortPublishedAt}
                </span>
                <TagList tags={genres} />
            </div>
        </a>
    );
};

CardEditorial.defaultProps = {
    regions: [],
    genres: [],
    image: {},
};

export default CardEditorial;
